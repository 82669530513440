.letter-card-container {
  border: 10px solid #FDF06F;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.letter-card-container .to-XYZ {
  color: #674B7C;
  font-size: 24px;
  max-width: calc(100% - 48px);
}

.letter-card-container .letter-body {
  color: rgba(0, 0, 0, 0.7);
}

.letter-card-container .letter-bottombar {
  display: flex;
  justify-content: space-between;
}

.letter-bottombar .letter-meta {
  display: flex;
  font-weight: bold;
  font-style: italic;
  color: #674B7C;
}

.createLetter, .letter-detail {
  background-color: #FDF06F;
  margin-top: -80px !important;
  position: relative;
  padding: 40px;
}


.createLetter .uk-container, .letter-detail .uk-container{
  max-width: 800px;
  width: 100%;
  background-color: white;
  margin-top: 120px;
  padding-bottom: 64px;
  box-sizing: border-box;
}

.createLetter .to-input {
  border: none;
  font-size: 36px;
  color: #674B7C;
  height: 48px;
}

.letter-detail .to-XYZ {
  font-size: 36px;
  color: #674B7C;
}

.createLetter .letter-body, .letter-detail .letter-body{
  border: none;
  padding-left: 80px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  white-space:pre-wrap;
}

.letter-detail .letter-bottombar {
  padding-left: 80px;
}

.createLetter .create-letter-wrapper, .letter-detail .letter-detail-wrapper {
  display: flex;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  position: relative;
  min-height: calc(100vh - 80px);
}

.save-btn-wrapper {
  position: absolute;
  right: 80px;
  top: 40px;
  font-size: 20px;
}

.save-btn-wrapper button, .back-btn.right {
  font-weight: bold;
  font-style: italic;
  color: #674B7C;
}

.save-btn-wrapper button:hover {
  text-decoration: none;
  color: #674B7C;
}

.back-btn {
  position: absolute;
  left: 80px;
  top: 60px;
  color: rgba(0, 0, 0, 0.7);
}

.back-btn.right {
  right: 80px;
  text-align: right;
}

.back-btn:hover {
  color: black;
}

.create-bottom-bar button{
  border: 0;
  cursor: pointer;
}

.create-bottom-bar button:focus {
  outline: none;
}

.create-bottom-bar {
  box-shadow: 0 0 9px 0 rgba(0,0,0,0.08);
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
}

.letter-detail .create-bottom-bar .uk-container {
  min-height: 40px;
}

.create-bottom-bar button:last-child {
  margin-left: 24px;
}

.edit-icon {
  position: absolute;
  right: 18px;
  top: 18px;
  background-color: rgb(255, 255, 0);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createLetter {
  z-index: 2;
}

@media (max-width:480px) {
  .letterListWrapper {
    width: calc(100vw - 8px);
    box-sizing: border-box;
    margin-left: -20px;
  }
}


@media (max-width:960px) {
  .createLetter, .letter-detail {
    padding: 24px;
  }
}

@media (max-width:640px) {
  .letter-card-container {
    padding: 24px;
  }
  .createLetter, .letter-detail {
    padding: 16px;
  }

  .createLetter .letter-body, .letter-detail .letter-body {
    padding-left: 40px;
  }

  .createLetter .to-input, .letter-detail .to-XYZ {
    font-size: 32px;
  }

  .save-btn-wrapper {
    right: 32px;
    top: 24px;
  }

  .letter-detail .uk-container {
    padding: 24px;
    padding-bottom: 72px;
    margin-top: 40px;
  }

  .letter-detail .letter-bottombar {
    padding-left: 40px;
  }

}

@media (max-width:480px) {
  .letter-card-container {
    padding: 24px;
  }
  .createLetter, .letter-detail {
    padding: 8px;
  }
  .createLetter .letter-body {
    padding-left: 32px;
  }

  .letter-detail .letter-body {
    padding-left: 0;
  }

  .create-letter-wrapper .uk-container {
    margin-top: 80px;
    padding: 8px;
    padding-bottom: 64px;
  }

  .letter-detail .uk-container {
    padding: 24px;
    margin-top: 40px;
    padding-bottom: 72px;
  }

  .letter-detail .letter-bottombar {
    padding-left: 0;
  }
}