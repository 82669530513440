
.uk-navbar-container:not(.uk-navbar-transparent) {
  background-color: white;
}

.uk-navbar-container {
  /* padding-left: 40px; */
  /* padding-right: 40px; */
}

.uk-navbar-item, .uk-navbar-nav>li>a,.uk-navbar-toggle {
  font-family: 'Lora', serif;
  text-transform: unset;
  font-size: 18px;
  color: rgba(103, 75, 124, 0.87);
}

.uk-navbar-nav>li:hover>a, .uk-navbar-nav>li>a.uk-open, .uk-navbar-nav>li>a:focus {
  color: #674B7C;
}

.uk-logo {
  font-family: 'Playfair Display', serif;
  color: #674B7C;
  font-weight: bold;
}

.signed-avatar {
  width: 40px;
  height: 40px;
  border: 2px solid rgb(253, 247, 52);
}

.uk-navbar-nav>li>a span {
  border-bottom: 2px solid transparent;
}

.uk-navbar-nav>li>a.active span{
  border-bottom: 2px solid #FDF06F;
}

footer .uk-container {
  padding-top: 24px;
}

footer li {
  margin-bottom: 16px;
}

footer li >a:hover {
  color: white;
}

.footer-link-container {
  margin-left: -24px !important;
}

.footer-link-container > li {
  margin-left: 24px;
}

.navbar-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
}