.user-profile-wrapper {
  display: flex;
  align-items: center;
}

.user-profile-wrapper .user-name {
  font-size: 24px;
  color: #674B7C;
  font-weight: 700;
}

.user-avatar img {
  width: 160px;
  height: auto;
  border-radius: 50%;
  border: 4px solid rgb(253, 240, 110);
}

.user-stats {
  display: flex;
  text-align: center;
}

.draft-no , .word-no {
  font-size: 40px;
  color: rgba(0, 0, 0, 0.87);
}

.draft-text, .word-text {
  font-weight: bold;
  color: #674B7C;
}

@media (max-width:640px) {
  .user-avatar img {
    width: 132px;
    height: auto;
    border-radius: 50%;
    border: 4px solid rgb(253, 240, 110);
  }
}