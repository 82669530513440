section.signUp, section.signIn {
  background-color: #FFFDE7;
  min-height: 100vh;
  margin-top: -80px;
}

.signup-text, .signin-text {
  font-size: 24px;
}

.signup-form-container, .signin-form-container {
  background-color: white;
  border-left: 5px solid #674B7C;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.signup-title, .signin-title {
  font-style: italic;
  font-weight: bold;
}

@media (min-width:640px) {
  .signup-text, .signin-text {
    font-size: 32px;
  }
}

@media (min-width:960px) {
  .signup-form-container, .signin-form-container {
    margin-left: 40px;
  }
}