body {
  margin: 0;
  padding: 0;
  font-family: 'Lora', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif !important;
}

.text-title {
  font-family: 'Playfair Display', serif !important;
}
.text-body {
  font-family:'Lora', serif !important;
}

.text-color-primary {
  color: #674B7C;
}

.text-color-white {
  color: white;
}

.text-color-grey77 {
  color: rgba(0, 0, 0, 0.77);
}

.bg-color-beige {
  background-color: #FFFDE7
}

.bg-color-primary {
  background-color: #674B7C;
}

.bg-color-white {
  background-color: white;
}

.margin-top-header {
  margin-top: -80px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-deco {
  display: inline-block;
  background: #674B7C !important;
  color: #FFFFFF !important;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 16px;
  box-shadow: 6px 6px 0 0px #FDF06F;
  font-size: 14px;
  letter-spacing: 1.25px;
  margin-bottom: 8px;
  transition: all 0.4s;
  white-space: nowrap;
}

.btn-deco.btn-small {
  padding: 2px 20px;
}

.btn-deco.btn-invert {
  background-color: white !important;
  color: #674B7C !important;
}

.btn-deco:hover {
  box-shadow: 3px 3px 0 0px #FDF06F;
  text-decoration: none;
}

.form-line-style {
  border: 0;
  border-bottom: 2px solid rgba(103, 75, 124, 0.38);
  padding-left: 0;
}

.form-line-style:focus {
  border-color: #674B7C;
}

.submit-btn-container {
  margin-top: 40px !important;
}

.or-container {
  font-size: 20px;
}

.uk-text-italic {
  font-style: italic;
}
.text-20px {
  font-size: 20px;
}

.user-profile .uk-tab>.uk-active>a {
  border-color: #674B7C;
}

.uk-container {
  padding-left: 24px;
  padding-right: 24px;
}

.uk-navbar-nav>li:last-child>a {
  padding-right: 0;
}

.post-payment  {
  word-break: break-all;
}

/* .App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.user-profile {
  flex: 1;
} */

/* Flex direction row */
/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-row\@s {
    flex-direction: row;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-row\@m {
    flex-direction: row;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-row\@l {
    flex-direction: row;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-row\@xl {
    flex-direction: row;
  }
}


/* Top Margin */
/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-top\@s {
    margin-top: 0 !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-remove-top\@m {
    margin-top: 0 !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-top\@l {
    margin-top: 0 !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-remove-top\@xl {
    margin-top: 0 !important;
  }
}

/* Flex direction row */
/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-medium-left\@s {
    margin-left: 40px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-medium-left\@m {
    margin-left: 40px;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-medium-left\@l {
    margin-left: 40px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-medium-left\@xl {
    margin-left: 40px;
  }
}